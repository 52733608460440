import * as React from "react";

import Layout from "../components/Layout";
import SectionContent from "../components/SectionContent";

// markup
const RaceSection = ({ location }) => {
  const title = "קטע מספר 6: מפארק אשכול (גן לאומי הבשור) לסכר רעים";
  return (
    <Layout path={location.pathname} title={title}>
      <SectionContent title={title}>
        <div>
          <p>
            <strong>אורך הקטע: 5.26 ק&quot;מ</strong>
          </p>
          <p>
            <strong>רמת הקושי: בינונית (ריצה טכנית)</strong>
          </p>
          <p>
            <strong>תיאור הקטע</strong>:
          </p>
          <p>
            מתחנת ההחלפה ממשיכים צפונה כ-70 מטרים ונכנסים בגשר בקר לתוך שביל
            אופניים סינגל הבשור. ממשיכים לרוץ בסינגל עד לסיומו. עוזבים את שטח
            המרעה המגודר באמצעות גשר בקר, ופונים ימינה בדרך עפר רחבה שמתעקלת
            שמאלה לכיוון צפון, וממשיכה לאורך נחל הבשור עד למפגש עם דרך
            השפד&quot;ן. במפגש פונים ימינה לכיוון צפון-מזרח כ-100 מטרים עד לסכר
            רעים.
          </p>
          <p>
            <strong>טופוגרפיה</strong>: מישור טכני
          </p>
          <p>
            <strong>מסלול</strong>: דרך סינגל אופניים, דרך עפר
          </p>
          <p>
            <strong>הערות</strong>: מסלול הסינגל דורש ריצה טכנית – שביל צר,
            פניות חדות עליות וירידות קצרצרות אך תלולות.
          </p>
          <p>
            <strong>בטיחות</strong>: הסינגל &#8211; דרך צרה ומפותלת ונדרשת{" "}
            <strong>ערנות לבורות</strong>. קטע הסינגל משמש גם כשטח מרעה לפרות:{" "}
            <strong>זהירות בגשרי הבקר</strong>! בדרך העפר הרחבה קיימים
            <strong>בורות גדולים</strong> (בולענים) שנפערו בגשמים האחרונים.
          </p>
          <p>
            <strong>תחנת החלפה</strong> : מפגש דרך השפד&quot;ן עם נחל הבשור, על
            סכר רעים. למגיעים מכביש 241, פונים צפונה לדרך השפד&quot;ן (דרך אספלט
            ברובה) כ- 1 ק&quot;מ מזרחית לצמת מעון, מול מתקן מקורות 'תחנת אברהם'
            כ-4 ק&quot;מ עד לסכר רעים. למגיעים מכביש 234 פונים לכיוון דרום מערב
            (ניצב לכביש), כ 1 ק&quot;מ צפונית לחות טל אור, על דרך לבנה כ – 2
            ק&quot;מ נסיעה עד לסכר.
          </p>
        </div>
        <div>
          <p>
            <iframe
              class="alltrails"
              src="https://www.alltrails.com/widget/map/--10427945?u=m"
              width="100%"
              height="400"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              title="AllTrails: Trail Guides and Maps for Hiking, Camping, and Running"
            />
          </p>
        </div>
      </SectionContent>
    </Layout>
  );
};

export default RaceSection;
